import React, { useState, useEffect, useMemo } from 'react';
import { useGlobalSelectedId, useGlobalHoveredId } from '../../data/StatusStore';
import { d2gRound } from '../utilities/Utilities';
import Swarm from '../charts/Swarm';
import SwarmMeta from '../charts/SwarmMeta';
import { onHoverHistogram, useGlobalActiveGeography, useGlobalActiveIndicator, onSetActiveIndicator, onSetActiveDBModuleId, onSetActiveModuleId, useGlobalActivePage } from '../../data/StatusStore';
import Colors from '../utilities/Colors';

export interface DBStackedStatsChartProps {
  options: any;
  data: any;
  width: string;
  height: number;
  colorClass: string;
  context: string;
}

const DBStackedStats: React.FC<DBStackedStatsChartProps> = ({ options, data, width, height, colorClass, context }) => {
  //console.log('C112624 DBStackedStats 1 options', options);
  const [preppedData, setPreppedData] = useState<any>([]);
  const [filteredData, setFilteredData] = useState<any>([]);
  const [tooltipData, setTooltipData] = useState<any>([]);
  const [indexLookup, setIndexLookup] = useState<any>({});
  const selectedId = useGlobalSelectedId();
  const activePage = useGlobalActivePage();
  const hoveredId = useGlobalHoveredId();
  const activeGeography = useGlobalActiveGeography();
  const _height = '80';

  useEffect(() => {
    if (selectedId) {
      prepData();
    }
  }, [selectedId]);

  const prepData = () => {
    if (selectedId) {
      const _filteredData = options.variables.filter((v: any) => {
        if (v.sort.Tooltip_only === '') {
          return v;
        }
      });
      setFilteredData(_filteredData);

      const _tooltipData = options.variables.filter((v: any) => {
        if (v.sort.Tooltip_only !== '') {
          return v;
        }
      });
      setTooltipData(_tooltipData);

      const _preppedData = _filteredData.map((v: any) => {
        return { name: v.label, value: data.dataJSON[selectedId][v.variable] };
      });
      setPreppedData(_preppedData);
    }
  };

  const fontSize2 = 12;
  const containerRef = React.useRef<HTMLDivElement | null>(null);

  // Group filteredData into chunks of 2
  const groupedData = useMemo(() => {
    const result = [];
    for (let i = 0; i < filteredData.length; i += 2) {
      result.push(filteredData.slice(i, i + 2));
    }
    return result;
  }, [filteredData]);

  const onClicked = (variable: string) => {
    if (activePage === "explorer") {
      onSetActiveModuleId(options.variables[0].sort.moduleId);
    }else{
      onSetActiveDBModuleId(options.variables[0].sort.moduleId);
    }
    onSetActiveIndicator(variable);
  };

  return (
    <div
      style={{
        position: 'relative',
        width,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
      }}
    >
      {groupedData.map((group, groupIndex) => (
        <div
          key={`group-${groupIndex}`}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: '10px',
            width: '100%',
          }}
        >
          {group.map((d: any, i: number) => {
            let _selected = null;
            let _hovered = null;
            if (d && data?.dataArray[d.variable]) {
              if (selectedId) {
                _selected = data?.keyIndexDataArray[d.variable][selectedId];
              }
              if (hoveredId) {
                _hovered = data?.keyIndexDataArray[d.variable][hoveredId];
              }
            }

            return d && data?.dataArray[d.variable] ? (
              <div
                ref={containerRef}
                style={{
                  width: '50%',
                  height: '100%',
                  position: 'relative',
                  display: 'flex',
                  alignItems: 'flex-start',
                  paddingRight: '0px',
                  cursor: 'pointer',
                }}
                key={`stacked-chart-card-hist-${groupIndex}-${i}`}
                onClick={() => onClicked(d.variable)}
              >
                <div
                  style={{
                    marginLeft: '0px',
                    fontSize: fontSize2,
                    lineHeight: `${fontSize2 + 2}px`,
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    paddingTop: '10px',
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      fontWeight: 'bold',
                      textAlign: 'left',
                    }}
                  >
                    {_selected
                      ? d2gRound(_selected.value, { DESCRIPTOR: data.meta['DESCRIPTOR'][d.variable] })
                      : _selected
                      ? d2gRound(_selected.value, { DESCRIPTOR: data.meta['DESCRIPTOR'][d.variable] })
                      : 'N/A'}
                  </div>
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      textAlign: 'left',
                    }}
                  >
                    {d.label.split("(")[0]}{ d.label.split("(")[1] && d.label.split("(")[1] !== "per 1,000 residents)" 
                    ? <span
                        style={{
                          color: 'rgba(0, 0, 0, 0.60)',
                        }}
                      >{`(${d.label.split("(")[1]}`}</span>
                    : null}
                  </div>
                </div>
                <div
                  style={{
                    marginLeft: '0px',
                    marginRight: '0px',
                    fontSize: fontSize2,
                    lineHeight: `${fontSize2 + 2}px`,
                    width: `calc(100% - 305px)`,
                    position: 'relative',
                  }}
                ></div>
              </div>
            ) : null;
          })}
        </div>
      ))}
    </div>
  );
};

export default DBStackedStats;
