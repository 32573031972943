import React, { useState, useEffect, FC } from 'react';
import { MapContainer, TileLayer, GeoJSON, Popup, CircleMarker, LayerGroup } from 'react-leaflet';
import L from 'leaflet';
import { feature } from 'topojson-client';
import 'leaflet/dist/leaflet.css';
import { GeoJsonObject, FeatureCollection } from 'geojson';
import { useData } from '../../../data/DataContext';
import Colors from '../../utilities/Colors';
import * as turf from 'turf';
import { cleanString, d2gRound, parseActivePointStatus } from '../../utilities/Utilities';
import { onHoverHistogram, onClickHistogram, useGlobalHoveredId, useGlobalSelectedId, useGlobalActiveGeography, 
    useGlobalActiveIndicator, useGlobalBivariateIndicator, useGlobalBivariateOverRide,
    useGlobalDBModuleId, useGlobalModuleId,  useGlobalActiveDBModuleId, useGlobalActiveModuleId, 
    useGlobalActivePage, useGlobalActivePointStatus, onSetActivePointStatus } from '../../../data/StatusStore';

interface DataItem {
    id: string;
    value: any;
}

interface PointLayersProps {
    activeGeography: string;
    pane?: string; // Add this line so pane can be passed in
    polypane?: string; // Add this line so pane can be passed in
}

export const PointLayers: FC<PointLayersProps> = (
    { activeGeography, pane, polypane }
) => {
    const {
        geoJsonDataPUMA, geoJsonDataTract, 
        geoJsonDataNTA, geoJsonDataBoro,
        isLoadingTopology, error, dataPoint
    } = useData();
    const activePointStatus = useGlobalActivePointStatus();
    const [parsedActivePointStatus, setParsedActivePointStatus] = useState<any>(null);
    const [polygonName, setPolygonName] = useState<string>("");
    /*const hoveredId = useGlobalHoveredId();
    const selectedId = useGlobalSelectedId();
    const activeIndicator = useGlobalActiveIndicator();
    const bivariateIndicator = useGlobalBivariateIndicator();
    const bivariateOverRide = useGlobalBivariateOverRide();
    const globalDBModuleId = useGlobalDBModuleId();
    const globalModuleId = useGlobalModuleId();
    const globalActiveDBModuleId = useGlobalActiveDBModuleId();
    const globalActiveModuleId = useGlobalActiveModuleId();
    const activePage = useGlobalActivePage();

    const [activeGeoJSON, setActiveGeoJSON] = useState<FeatureCollection | null>(null);
    const [selectedGeoJSON, setSelectedGeoJSON] = useState<FeatureCollection | null>(null);
    const [mapId, setMapId] = useState<number>(0);
    */
    const handleMarkerClick = (point: any, key:string) => {
        console.log("I011025 Marker clicked:", point, key);
        console.log("D010925 PointLayers > activePointStatus", activePointStatus);
        console.log("D010925 PointLayers > parsedActivePointStatus", parsedActivePointStatus);
        //onSetActivePointStatus(`${point.latitude}*${point.longitude}*${cleanString(key)}`);
        let constructedStatus = ""
        let _point:any = {};
        if (point.geometry) {
            const centroid = turf.centroid(point);
            const [centroidLng, centroidLat] = centroid.geometry.coordinates;
            //const centroid = L.polygon(point.geometry.coordinates).getBounds().getCenter();
            _point["latitude"] = centroidLat;//centroid.lat;
            _point["longitude"] = centroidLng; //centroid.lng;
            let __name = "";
            Object.keys(point.properties).forEach(propertyKey => {
                _point[propertyKey] = point.properties[propertyKey];
                if (propertyKey.endsWith('_name')) {
                    __name = _point[propertyKey];
                }
            });
            setPolygonName(__name);
        }else{
            _point = point;
        }
        console.log("D010925 _point", _point);
        if (parsedActivePointStatus) {
            Object.keys(parsedActivePointStatus).forEach(statusKey => {
                const status = parsedActivePointStatus[statusKey];
                console.log("F121724 statusKey", statusKey);
                console.log("F121724 key", key);
                console.log("F121724 cleanString(key)", cleanString(key));
                console.log("F121724 status", status);
                if (statusKey === cleanString(key)) {
                    // update lat/lng
                    constructedStatus += constructedStatus === "" 
                        ? `${_point.latitude}*${_point.longitude}*${statusKey}`
                        : `~${_point.latitude}*${_point.longitude}*${statusKey}`;
                }else{
                    // clear lat/lng
                    constructedStatus += constructedStatus === "" 
                    ? `${"na"}*${"na"}*${statusKey}`
                    : `~${"na"}*${"na"}*${statusKey}`;
                }
            });
        }
        onSetActivePointStatus(constructedStatus);
    };

    useEffect(() => {
        console.log("CE121624 ----- dataPoint", dataPoint);
    }, [dataPoint]);

    useEffect(() => {
        console.log("B121624 activePointStatus", activePointStatus);
        if (activePointStatus) {
            setParsedActivePointStatus(parseActivePointStatus(activePointStatus));
        }
    }, [activePointStatus]);

    useEffect(() => {
        console.log("D011025 PointLayers > parsedActivePointStatus", parsedActivePointStatus);
    }, [parsedActivePointStatus]);

    return (
        <>
        {/*console.log("J011025 dataPoint", dataPoint)*/}
        {dataPoint && Object.keys(dataPoint).map(key => {
            const pointData = dataPoint[key];
            if (pointData.active) {
                if (pointData.data.features) {
                    // This is polygon data
                    //console.log("J011025 dataPoint[key]", dataPoint[key])
                    return (
                        <LayerGroup key={key} pane={polypane || "polyPane"}>
                            <GeoJSON
                                data={pointData.data}
                                pane={polypane || "polyPane"}
                                style={{
                                    fillColor: dataPoint[key].item.color,
                                    weight: 1,
                                    opacity: 1,
                                    color: dataPoint[key].item.color,
                                    fillOpacity: 0.5
                                }}
                                eventHandlers={{
                                    click: (e) => handleMarkerClick(e.layer.feature, key)
                                }}
                            >
                                <Popup>
                                    <b>{key}</b><br />
                                    {polygonName ? polygonName : ""}
                                </Popup>
                            </GeoJSON>
                        </LayerGroup>
                    );
                }else{
                    // console.log("EE121624 pointData.active", pointData)
                    // Instead of converting to GeoJSON and using <GeoJSON />, 
                    // we directly create <CircleMarker /> components for each point.
                    return (
                        <LayerGroup key={key} pane={pane || "pointsPane"}>
                            {pointData.data.map((point: any, idx: number) => {
                                let _name = null;
                                Object.keys(point).forEach(pointKey => {
                                    if (pointKey.endsWith('_name')) {
                                        _name = point[pointKey];
                                    }
                                });
                                if (isNaN(Number(point.longitude)) || isNaN(Number(point.latitude))) {
                                    return null;
                                }
                                return (
                                    <CircleMarker
                                        key={`point_${key}_${idx}`}
                                        pane={pane || "pointsPane"}
                                        center={[Number(point.longitude), Number(point.latitude)]}
                                        radius={5}
                                        fillColor={dataPoint[key].item.color}
                                        stroke={false}
                                        weight={1}
                                        opacity={1}
                                        fillOpacity={1}
                                        eventHandlers={{
                                            click: () => handleMarkerClick(point, key)
                                        }}
                                    >
                                        <Popup 
                                            //autoPan={false}
                                        >
                                            <b>{key}</b><br />
                                            {_name ? _name : ""}
                                        </Popup>
                                    </CircleMarker>
                                );
                            })}
                        </LayerGroup>
                    );
                }
            }
            return null;
        })}
        </>
    );

};
