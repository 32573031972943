import { active } from 'd3';
import React, { useState, useEffect } from 'react';

interface LocationImageProps {
  selectedId: string | null; // Assuming the selectedId matches 'bk0101', 'bk0102', etc.
  activeGeography: string | null; // Assuming the selectedId matches 'bk0101', 'bk0102', etc.
  allData: any;
}

export const DynamicImage: React.FC<LocationImageProps> = ({ selectedId, activeGeography, allData }) => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [imageStatus, setImageStatus] = useState<string>("failed");

  useEffect(() => {
    if (!selectedId) {
      return;
    }
    const loadImage = async () => {
      setImageStatus("loading"); 
      console.log('010825 selectedId', selectedId);
      selectedId = activeGeography === 'Tract' 
        ? selectedId 
          ? allData[activeGeography].dataJSON[selectedId].GEO_ID_NTA 
            ? allData[activeGeography].dataJSON[selectedId].GEO_ID_NTA 
            : ""
          : ""
        : selectedId
          ? selectedId
          : "";  //allData[selectedId] ? allData[selectedId].id : selectedId;
      let prefix = "";
      let suffix = selectedId;
      console.log('010825 selectedId', selectedId);
      if (activeGeography === 'NTA' || activeGeography === 'Tract') {
        prefix = selectedId ? selectedId.slice(0, 2).toLowerCase() : '';
        suffix = selectedId ? selectedId.slice(-4) : '';
      }
      if (activeGeography === 'CD') {
        suffix = `cd${selectedId}`;
      }
      let geoDir = activeGeography === 'Tract' ? 'NTA' : activeGeography;
      try {
        // Dynamically import the image based on selectedId
        let image;
        if (activeGeography === 'NTA' || activeGeography === 'Tract') {
          image = await import(`../../assets/pngs/${geoDir}/${prefix}/${prefix}${suffix}.png`);
        } else {
          image = await import(`../../assets/pngs/${geoDir}/${suffix}.png`);
        }
        setImageSrc(image.default);
        setImageStatus("success"); 
      } catch (error) {
        console.error('Failed to load image:', error);
        setImageSrc(null); // Fallback to null or a default image
        setImageStatus("failed"); 
      }
    };

    if (selectedId) {
      loadImage();
    }
  }, [selectedId]);

  if (!selectedId) {
    return null;
  }
  if (imageStatus === "failed") {
    return null;
  }
  if (imageStatus === "loading") {
    return <p style={{
              color: "#a8a6a6",
              fontSize: "10pt",
              textAlign: "center",
              paddingTop: "10px", 
            }}
            >Loading image...</p>;
  }
  if (!imageSrc) {
    return null;
  }

  return <img src={imageSrc} alt={`Module ${selectedId}`} style={{ width: '100%' }} />;
};
