import React, { useEffect, useState } from 'react';
import { useMap, useMapEvent } from 'react-leaflet';
import L from 'leaflet';
import { onSetActiveGeography, useGlobalActiveGeography, useGlobalActiveIndicator, useGlobalCachedActiveIndicators,
  /*onSetCachedActiveIndicators,  onSetCachedSelectedIds, useGlobalCachedActiveIndicators, 
  useGlobalCachedSelectedIds,*/ onSetActiveIndicator, onSetBivariateIndicator } from '../../data/StatusStore';
import GeoChangeModal from './GeoChangeModal';

interface MapRadioControlProps {
  categoryOptions: { category:string; options:any }[];
  defaultValue: string;
  onChange: (value: string) => void;
  dataMeta: any;
  allData: any;
}

const MapRadioControl: React.FC<MapRadioControlProps> = ({ categoryOptions, defaultValue, onChange, dataMeta, allData }) => {
  const map = useMap();

  const [isModalVisible, setModalVisible] = useState(false);
  const [category, setCategory] = useState("CD");
  //const openModal = () => setModalVisible(true);
  const closeModal = () => setModalVisible(false);

  const activeGeography = useGlobalActiveGeography();
  const activeIndicator = useGlobalActiveIndicator();
  //console.log("A120624 activeGeography", activeGeography);
  //console.log("A120624 activeIndicator", activeIndicator);
  //console.log("A120624 dataMeta", dataMeta);
  const cachedActiveIndicators = useGlobalCachedActiveIndicators();
  //const cachedSelectedIds = useGlobalCachedSelectedIds();
  const [selectedValue, setSelectedValue] = useState<string>(activeGeography);

  useEffect(() => {
    // Create a Leaflet Control to position the radio buttons on the map
    const controlContainer = L.DomUtil.create('div', 'leaflet-control leaflet-bar');
    const customControl = new L.Control({ position: 'topright' }); // You can adjust the position

    customControl.onAdd = function() {
      L.DomEvent.disableClickPropagation(controlContainer);
      return controlContainer;
    };

    customControl.addTo(map);

    return () => {
      customControl.remove();
    };
  }, [map]);

  useEffect(() => {
    setSelectedValue(activeGeography);
  }, [activeGeography]);

  useMapEvent('click', () => {
    // Optional: Respond to map clicks or other events
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, category:string) => {
    switch (category) {
        case "by Geography":
          //onSetBivariateIndicator(cachedActiveIndicators[e.target.value].bivariate); // This will prevent the cached bivariateIndicator from being set
          //onSetActiveIndicator(cachedActiveIndicators[e.target.value].active); // This will prevent the cached activeIndicator from being set
          onSetActiveGeography(e.target.value);
          break;
        default:
            break;
    }
    onChange(e.target.value);
  };
  const handleChangeWhenUnavailable = (geo:string) => {
    //onSetBivariateIndicator(cachedActiveIndicators[e.target.value].bivariate); // This will prevent the cached bivariateIndicator from being set
    //onSetActiveIndicator(cachedActiveIndicators[e.target.value].active); // This will prevent the cached activeIndicator from being set
    onSetActiveIndicator(cachedActiveIndicators[geo].active);
    onSetActiveGeography(geo);
    onChange(geo);
  };
  const openModal = (e: React.ChangeEvent<HTMLInputElement>, category:string) => {
    setCategory(e.target.value);
    setModalVisible(true);
  };

  return (
    <div className="leaflet-control-container">
    <div className="leaflet-top leaflet-left">
    <div className="leaflet-control leaflet-control-custom" id='mapRadios'>
        <GeoChangeModal 
          isVisible={isModalVisible} 
          onClose={closeModal} 
          category={category} 
          handleChangeWhenUnavailable={ handleChangeWhenUnavailable }
        />
        {categoryOptions.map((category:any) => {
            return (
              <form 
                key={`${category.category}_form`}
                className="map-radio-control">
                <label 
                key={`${category.category}_label`}
                className="map-radio-control-label">{category.category}</label>{
                category.options.map((option:any) => {
                  let _active = dataMeta[option.value][activeIndicator] == "X" ? true : false;
                  if (!_active && option.value === 'CD'){
                    /*console.log("C012225 _active", _active);
                    console.log("C012225 activeIndicator", activeIndicator);
                    console.log("C012225 dataMeta[option.value]", dataMeta[option.value]);
                    console.log("C012225 option.value", option.value);
                    console.log("C012225 allData", allData);
                    console.log("C012225 allData.NTA.meta[option.value]", allData.NTA.meta[option.value]);
                    console.log("C012225 allData.NTA.meta.NTA[activeIndicator]", allData.NTA.meta.NTA[activeIndicator]);*/
                    _active = allData.NTA.meta.NTA[activeIndicator] == "X" ? true : false; // if CD is unavailable, check if NTA is available (NTA is mapped to CD, when available)
                  }
                //console.log("A120624 option.value", option.value);
                //console.log("A120624 dataMeta[option.value]", dataMeta[option.value]);
                //console.log("A120624 dataMeta[option.value][activeIndicator]", dataMeta[option.value][activeIndicator]);
                return (
                  option.available ?
                    <label 
                      style={{
                        color: _active ? "#000000" : "#9E9C9C",
                      }}
                      className={ _active ? 'active-option' : 'inactive-option'}
                      key={`${option.value}_${category.category}_label`}>
                        <input
                            key={`${option.value}_${category.category}_input`}
                            //disabled={dataMeta[option.value][activeIndicator] == "X" ? !option.enabled : true}
                            disabled={!option.enabled}
                            type="radio"
                            name="mapRadioControl"
                            value={option.value}
                            checked={selectedValue === option.value}
                            onChange={(e: any) => {
                              _active 
                                ? handleChange(e, category.category)
                                : openModal(e, category.category);
                            }}
                        />
                        <div
                          className="map-radio-control-text"
                        >{option.label}</div>
                    </label>
                  : null
                )})}
            </form>
        )})}
    </div>
    </div>
    </div>
  );
};

export default MapRadioControl;
