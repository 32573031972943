import React, { useState, useEffect, useRef } from 'react';
import Colors from '../utilities/Colors';
import { useGlobalHoveredId, useGlobalSelectedId } from '../../data/StatusStore';
import { d2gRound } from '../utilities/Utilities';

interface HistogramChartMetaProps {
  dataArray: DataItem[];
  indicator: any;
  data: any;
}

interface DataItem {
  id: string;
  value: number;
  x?: number;
  y?: number;
}

const HistogramMeta: React.FC<HistogramChartMetaProps> = ({
  dataArray,
  indicator,
  data
}) => {

  const hoveredId = useGlobalHoveredId();
  const selectedId = useGlobalSelectedId() as any;
  //console.log("A100724 data", data);
  //console.log("A100724 indicator", indicator);
  //console.log("A100724 dataArray", dataArray);
  return (
    <>
      <div className="module-child-1-meta">      
        <div className='module-meta-stat-label'>       
          <div className="module-meta-stat">
            {selectedId && indicator && indicator.variable ? d2gRound(data.dataJSON[selectedId][indicator.variable], { DESCRIPTOR: data.meta["DESCRIPTOR"][indicator.variable] }) : "NA"}
          </div>
          {/*<div className="module-meta-label">
            {indicator.label}
          </div>*/}
        </div>
      </div>
      <div
          style={{
            marginRight:"15px",
          }}
        >
        {`${data.meta["NOTES"][indicator.variable] 
            ? (data.meta["NOTES"][indicator.variable] != "") 
              ? data.meta["NOTES"][indicator.variable] 
              : data.meta["DISPLAY_NAME"][indicator.variable]
            : data.meta["DISPLAY_NAME"][indicator.variable]}`}
        <br/><div style={{ color: '#9E9C9C', paddingTop:'5px'}}>Source: <a className="source-link" href={`${data.meta["SOURCE URL"][indicator.variable]}`} target='_blank'>{`${data.meta["SOURCE NAME"][indicator.variable]}`}</a></div> 
      </div>
    </>
  );
};

export default HistogramMeta;
