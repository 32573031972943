import React, { useState, useEffect, useRef, useTransition } from 'react';
import { useGlobalSelectedId, onSetActiveIndicator, onSetActiveDBModuleId, onSetActiveModuleId, useGlobalActivePage } from '../../data/StatusStore';
import { d2gRound } from '../utilities/Utilities';
import Colors from '../utilities/Colors';
import { useData } from '../../data/DataContext';

export interface DBStatListChartProps {
  options: any;
  data: any;
  width: number;
  height: number;
  colorClass: string;
}

const MARGIN_X = 85;
const MARGIN_Y = 10;

const DBStatList: React.FC<DBStatListChartProps> = ({ options, data, width, height, colorClass }) => {
  const [preppedData, setPreppedData] = useState<any>([]);
  const [barHeight, setBarHeight] = useState<number>(40);
  const [spaceHeight, setSpaceHeight] = useState<number>(10);
  const selectedId = useGlobalSelectedId();
  const activePage = useGlobalActivePage();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [dimensions, setDimensions] = useState<{ width: number; height: number }>({ width, height });
  const [maxDeviation, setMaxDeviation] = useState<number>(1);
  const [stdDeviations, setStdDeviations] = useState<{ [key: string]: number }>({});
  const [resetBars, setResetBars] = useState<{ [key: string]: boolean }>({});
  const [isPending, startTransition] = useTransition();
  const { dataNYC } = useData();
  const [tooltip, setTooltip] = useState<{ x: number; y: number; obj: any } | null>(null);

  const handleMouseEnter = (event: React.MouseEvent<SVGRectElement>, obj: any) => {
    if (containerRef.current) {
      const { nativeEvent } = event; // Access native event
      const offsetX = nativeEvent.offsetX;
      const offsetY = nativeEvent.offsetY;
  
      setTooltip({
        x: offsetX, // Tooltip position now respects container context
        y: offsetY,
        obj,
      });
    }
  };
  
  const handleMouseMove = (event: React.MouseEvent<SVGRectElement>) => {
    if (containerRef.current) {
      const { nativeEvent } = event;
      const offsetX = nativeEvent.offsetX;
      const offsetY = nativeEvent.offsetY;
  
      setTooltip((prev) => prev && {
        ...prev,
        x: offsetX,
        y: offsetY,
      });
    }
  };  

  const handleMouseLeave = () => {
    setTooltip(null);
  };

  useEffect(() => {
    console.log("H012225 DBStatList selectedId", selectedId);
    if (containerRef.current) {
      const resizeObserver = new ResizeObserver(entries => {
        if (!entries || entries.length === 0) return;
        const { width, height } = entries[0].contentRect;
        setDimensions({ width, height });
      });
      resizeObserver.observe(containerRef.current);
      return () => {
        if (containerRef.current) {
          resizeObserver.unobserve(containerRef.current);
        }
      };
    }
  }, []);

  useEffect(() => {
    startTransition(() => {
      if (selectedId) prepData();
    });
  }, [selectedId]);

  const prepData = () => {
    console.log("H012225 selectedId", selectedId);
    if (selectedId) {
      //const _filteredData = options.variables.filter((v: any) => !v.sort.Tooltip_only);
      const _filteredData = options.variables.filter((v: any) => {
        //if ((v.sort.Tooltip_only === "")) {
        if ((v.sort.Tooltip_only === "") && (v.sort["Shape Role"] !== "pyramid")) {
          return { v };
        }
      });
      const _tooltipData = options.variables.filter((v: any) => v.sort.Tooltip_only);
      const _preppedData = _filteredData.map((v: any) => {
        const rawValue = data.dataJSON[selectedId][v.variable];
        const isNA = rawValue === undefined || rawValue === null || rawValue === "NA";
        const value = !isNA && !isNaN(rawValue) ? rawValue : 'N/A';
        const deviation = !isNA && !isNaN(rawValue) ? data.meta.avg[v.variable] - rawValue : 0;
        let ttVariable = '';
        _tooltipData.forEach((s: any) => {
          if (s.sort.Tooltip_only === v.variable) {
            ttVariable = s.variable;
          }
        });
        const tooltip = data.dataJSON[selectedId][ttVariable];
        const presentableNYCAvg = dataNYC 
          ? d2gRound(dataNYC.dataJSON[0][v.variable], { DESCRIPTOR: data.meta["DESCRIPTOR"][v.variable] }) 
          : null; 

        const presentableTooltip = tooltip 
          ? d2gRound(tooltip, { DESCRIPTOR: data.meta["DESCRIPTOR"][ttVariable] }) 
          : d2gRound(value, { DESCRIPTOR: data.meta["DESCRIPTOR"][v.variable] }) 

        const presentableLabel = tooltip 
          ? data.meta["DISPLAY_NAME"][ttVariable].split("(")[0]
          : data.meta["DISPLAY_NAME"][v.variable].split("(")[0] 
        
        return {
          name: v.label,
          deviation: isNA ? 0 : deviation,
          value,
          tooltip: presentableTooltip,
          tooltipLabel: presentableLabel,
          nycAvg: presentableNYCAvg,
          average: data.meta.avg[v.variable],
          max: data.meta.max_value[v.variable],
          min: data.meta.min_value[v.variable],
          variable: v.variable,
          index: data.keyIndexDataArray[v.variable] ? data.keyIndexDataArray[v.variable][selectedId].index : 0,
          na: data.meta.na_count[v.variable],
          len: data.dataArray[v.variable] ? data.dataArray[v.variable].length : 0,
        };
      });

      setPreppedData(_preppedData);
    }
  };

  const onClicked = (variable: string) => {
    if (activePage === "explorer") {
      onSetActiveModuleId(options.variables[0].sort.moduleId);
    }else{
      onSetActiveDBModuleId(options.variables[0].sort.moduleId);
    }
    onSetActiveIndicator(variable);
  };

  const fontSize2 = 12;
  const labelWidth = "100%";

  return (
    <div ref={containerRef} style={{ width: '100%', height: '100%', position: 'relative', display: 'flex', alignItems: 'flex-start', paddingRight: "16px" }}>
      <div style={{ marginLeft: '0px', marginRight: '0px', fontSize: fontSize2, lineHeight: `${fontSize2 + 2}px`, width: labelWidth }}>
        {preppedData.map((d: any, i: number) => (
          <div 
            key={`info-${i}`} 
            style={{ cursor:'pointer', fontSize: fontSize2, lineHeight: `${fontSize2 + 2}px`, display: "inline-flex", justifyContent: "space-between", paddingBottom: '10px', width: "100%"}}
            onClick={() => onClicked(d.variable)}
          >
            <div style={{ fontWeight: 'bold', width: "75px", alignContent: "flex-start" }}>
              {d.value === 'N/A' ? 'N/A' : d2gRound(d.value, { DESCRIPTOR: data.meta["DESCRIPTOR"][d.variable] })}
            </div>
            <div style={{ fontWeight: 'normal', width: "calc(100% - 75px)", alignContent: "flex-start", paddingRight: "15px" }}>
                {d.name.split("(")[0].replace("Industry Category: ", "")}{ d.name.split("(")[1] 
                  ? <span
                      style={{
                        color: 'rgba(0, 0, 0, 0.60)',
                      }}
                    >{`(${d.name.split("(")[1]}`}</span>
                  : null}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DBStatList;
