import React, { useState, useEffect } from 'react';
import * as d3 from 'd3';
import Colors from '../utilities/Colors';
import { d2gRound } from '../utilities/Utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { onHoverHistogram, onClickHistogram, onSetActiveIndicator, useGlobalActiveGeography, onSetActiveDBModuleId, onSetActiveModuleId, useGlobalActivePage } from '../../data/StatusStore';
import { useGlobalSwarmCalibration } from '../../data/GlobalStore';
import { relative } from 'path';

interface BaseDataItem {
  id: string;
  value: number;
}

interface DodgeDataItem extends BaseDataItem {
  x: number;
  y: number;
  next: DodgeDataItem | null;
}

interface DataItem {
  id: string;
  value: number;
  x?: number;
  y?: number;
  tooltip?: any;
}

interface SwarmStaticProps {
  dataArray: BaseDataItem[];
  histMax: number;
  histMin: number;
  histNA: number;
  histLength: number;
  chartId: string;
  nodes: DataItem[];
  setNodes: Function;
  indicator: string;
  colorClass: string;
  DESCRIPTOR: string;
  labelOnly?: any;
  allData: any;
  options?: any;
}

const SwarmStatic: React.FC<SwarmStaticProps> = ({
  dataArray,
  chartId,
  histMax,
  histMin,
  histNA,
  histLength,
  nodes,
  setNodes,
  indicator,
  colorClass,
  DESCRIPTOR,
  labelOnly,
  allData,
  options
}) => {

const swarmCalibration = useGlobalSwarmCalibration();
const activeGeography = useGlobalActiveGeography();
const activePage = useGlobalActivePage();

const width = "100%";
const height = "100%";

const [axisLabels, setAxisLabels] = useState<any>({});
const [isLoading, setIsLoading] = useState<boolean>(true);
const [collisionDiameter, setCollisionDiameter] = useState<number>(swarmCalibration[activeGeography].collisionDiameter);
const [pointDiameter, setPointDiameter] = useState<number>(swarmCalibration[activeGeography].radius * 2);
//const [pointDiameter, setPointDiameter] = useState<number>(swarmCalibration[activeGeography].pointDiameter);
const [padding, setPadding] = useState<number>(swarmCalibration[activeGeography].padding);
const [radius, setRadius] = useState<number>(swarmCalibration[activeGeography].radius);

function calculateSwarmPositions(data: DataItem[], min: number, max: number, plotWidth: number, pointDiameter: number, collisionDiameter:number, stackCount:number, _testEachPoint:boolean, allData:any, labelOnly:any): DataItem[] {
  const radius = pointDiameter / 2;
  const collisionRadius = collisionDiameter; // / 2;
  let lastPoint: DataItem | null = null;
  let direction = 1;  // Start by placing points above the axis (1 for above, -1 for below)
  let run = 0;
  //console.log("A120724 data", data)
  //console.log("A120724 labelOnly", labelOnly)
  //console.log("A120724 options", options)
  //console.log("A120724 indicator", indicator)
  //console.log("BBB111624 allData", allData)
  //console.log("BBB111624 labelOnly[0].sort.VARIABLE_NAME", labelOnly[0].sort.VARIABLE_NAME)
  //console.log("G092924 nodes", nodes)
  let tooltipVar = labelOnly.length > 0 
    ? labelOnly.filter((item:any) => item.sort.Tooltip_only === indicator)
    : null;
  //console.log("A120724 tooltipVar", tooltipVar)
  data.forEach((item, index) => {
      item.tooltip = null
      if (tooltipVar && tooltipVar[0]){
        //console.log("BBB111624  labelOnly[0].sort.VARIABLE_NAME", labelOnly[0].sort.VARIABLE_NAME);
        //console.log("BBB111624  allData.dataJSON[item.id]", allData.dataJSON[item.id]);
        //console.log("BBB111624  allData.dataJSON[item.id][labelOnly[0].sort.VARIABLE_NAME]", allData.dataJSON[item.id][labelOnly[0].sort.VARIABLE_NAME]);
        item.tooltip = allData.dataJSON[item.id][tooltipVar[0].sort.VARIABLE_NAME];
      };
      // Calculate the x position as a percentage of the value between min and max
      item.x = (((item.value - min) / (max - min)) * (plotWidth - pointDiameter )) + (pointDiameter / 2);
      if (isNaN(item.x) || item.x == undefined){
        item.x = -2;
      }
      // Initialize y position
      item.y = 0;
   
      if (lastPoint && Math.abs(lastPoint.x! - item.x!) < collisionRadius * 2) {
          // If overlapping, adjust y position. Alternate placing points above and below the line.
          item.y = (run * (radius * 2)) * direction;
          //run++;
          if (direction === 1) {
            run = run + 1;
          }
          // Toggle direction for the next potential overlap
          direction *= -1;
      } else {
          // Reset direction to 1 (above) whenever there is no overlap
          direction = 1;
          run = 0;
          if (!_testEachPoint) {
            lastPoint = item;
          }
      }
      if (Math.abs(item.y) > stackCount) {
        run=0;
        lastPoint = item;
      }

      // Update the lastPoint reference for the next iteration
      if (_testEachPoint){
        lastPoint = item;
      }
  });

  return data;
}

interface AxisLabel {
  tickValue: number;
  width: string;
}
// creates a dynamic number of ticks
function ___computeAxisLabels(min: number, max: number, minTicks: number = 3, maxTicks: number = 8): AxisLabel[] {
  let range = max - min;
  let idealStep = range / (minTicks + (maxTicks - minTicks) / 2);
  let stepSize = getNiceNumber(idealStep, false);
  let tickCount = Math.ceil(range / stepSize);

  // Adjust stepSize if tickCount is not within the desired range
  while (tickCount < minTicks) {
    stepSize /= 2;
    tickCount = Math.ceil(range / stepSize);
  }
  while (tickCount > maxTicks) {
    stepSize *= 2;
    tickCount = Math.ceil(range / stepSize);
  }

  let ticks:any = [];
  for (let i = 0; i <= tickCount; i++) {
    ticks.push(min + i * stepSize);
  }
  if (ticks[ticks.length - 1] !== max) ticks[ticks.length - 1] = max; // Ensure the last tick is exactly max

  // Calculate widths
  const totalTicks = ticks.length;
  const labels: AxisLabel[] = ticks.map((tick:any, index:number) => {
    if (index < totalTicks - 1) {
      let segmentWidth = ((ticks[index + 1] - tick) / range * 100).toFixed(2) + "%";
      return { tickValue: tick, width: segmentWidth };
    }
    return { tickValue: tick, width: "0%" };
  });

  // Remove placeholder and adjust last segment width if necessary
  labels.pop();
  let lastWidth = 100 - labels.reduce((acc, curr) => acc + parseFloat(curr.width), 0);
  labels.push({ tickValue: max, width: lastWidth.toFixed(2) + "%" });

  return labels;
}
//Creates 3 ticks
function computeAxisLabels(min: number, max: number, minTicks: number = 3, maxTicks: number = 8): AxisLabel[] {
  let range = max - min;

  // Compute the three ticks: min, midpoint, and max
  let mid = min + range / 2;

  let ticks = [min, mid, max];

  // Calculate widths for the three segments
  const labels: AxisLabel[] = ticks.map((tick: number, index: number) => {
    let segmentWidth = "0%";

    // For the first and second tick, calculate segment width
    if (index < ticks.length - 1) {
      segmentWidth = ((ticks[index + 1] - tick) / range * 100).toFixed(2) + "%";
    }

    return { tickValue: tick, width: segmentWidth };
  });

  // The last segment width should be 0% (or it's the last tick)
  labels[labels.length - 1].width = "0%";

  return labels;
}


function getNiceNumber(range: number, round: boolean): number {
  const exponent = Math.floor(Math.log10(range));
  const fraction = range / Math.pow(10, exponent);
  let niceFraction: number;

  if (fraction <= 1) niceFraction = 1;
  else if (fraction <= 2) niceFraction = 2;
  else if (fraction <= 2.5) niceFraction = 2.5;
  else if (fraction <= 5) niceFraction = 5;
  else if (fraction <= 7.5) niceFraction = 7.5;
  else niceFraction = 10;

  return niceFraction * Math.pow(10, exponent);
}



/*const radius = dataArray 
  ? dataArray.length > 100 
    ? 1 
    : 2
  :1; // Radius of the circles on the plot
const padding = dataArray
  ? dataArray.length > 100 
    ? .25 
    : 0.5
  :.25; // Padding between circles on the plot*/
  
const plotWidth = 100; // Total width of the plot in percent

/*const pointDiameter = dataArray
  ? dataArray.length > 100 
    ? .75 
    : 2
  : .75; // The smaller this number, the less space between points
const collisionDiameter = dataArray
  ? dataArray.length > 100 
    ? swarmCalibration.Over100.collisionDiameter
    : 1
  : .15; // The smaller this number, the more sensitive the collision detection*/

useEffect(() => {
  //console.log("G092924 chartId", chartId)
  let _collisionDiameter = swarmCalibration[activeGeography].collisionDiameter;
  let _radius = swarmCalibration[activeGeography].radius;
  let _pointDiameter = _radius * 2;
  //let _pointDiameter = swarmCalibration[activeGeography].pointDiameter;
  let _padding = swarmCalibration[activeGeography].padding;
  let _stackCount = swarmCalibration[activeGeography].stackCount;
  let _testEachPoint = swarmCalibration[activeGeography].testEachPoint === "TRUE" ? true : false;
  setCollisionDiameter(_collisionDiameter);
  setPointDiameter(_pointDiameter);
  setRadius(_radius);
  setPadding(_padding);
  /*console.log("C051024 swarmCalibration", swarmCalibration)
  console.log("C051024 _collisionDiameter", _collisionDiameter)
  console.log("C051024 _pointDiameter", _pointDiameter)
  console.log("C051024 _radius", _radius)
  console.log("C051024 _padding", _padding)
  console.log("C051024 indicator", indicator)
  console.log("D051024 histMax", histMax)
  console.log("D051024 histMin", histMin)*/
  const swarmData = calculateSwarmPositions(dataArray, histMin, histMax, plotWidth, _pointDiameter, _collisionDiameter, _stackCount, _testEachPoint, allData, labelOnly);
  setNodes(swarmData);
  const _axisLabels = computeAxisLabels(histMin, histMax, /*parseInt(width),*/ 5); 
  //console.log("E051024 histMin, histMax, parseInt(width)", histMin, histMax, parseInt(width))
  //console.log("E051024 _axisLabels", _axisLabels)
  setAxisLabels(_axisLabels);
  setIsLoading(false);

}, [dataArray, height, width, histMax, histMin, histLength, /*collisionDiameter, pointDiameter,*/ swarmCalibration]);

useEffect(() => {
  //console.log("C051024 collisionDiameter", collisionDiameter)
  //console.log("C051024 pointDiameter", pointDiameter)
}, [collisionDiameter, pointDiameter]);


return (      
  <div key={`svg_swarm_static_container_${chartId}`}  className='swarm-container'>
    { isLoading 
  ? (
    <div key={`svg_swarm_static_spinner_${chartId}`} className="loading-spinner">
      <FontAwesomeIcon icon={faSpinner} spin />
    </div>
  ) : (
    <svg key={`svg_swarm_static_${chartId}`} width={width} height={height} className="swarm-chart-static">
      {nodes.map((node, i) => {
      //console.log("D112624 node, chartId", node, chartId)
      const color = Colors.getColorQuintile(i, histNA, histLength, colorClass);
      const nodesNotNAN =nodes.findIndex(node => node.x ? node.x >= 0 : false)
      return (
        <circle
        key={`${node.id}_${chartId}_${i}`}
        cx={node.x 
          ? `${node.x}%` 
          : `${radius}px`}
        cy={node.y 
          ? `${((parseInt(height) / 2 - radius - padding - node.y) / parseInt(height)) * 100}%`
          : `${((parseInt(height) / 2 - radius - padding) / parseInt(height)) * 100}%`} // Adjust y position based on radius and padding
        r={radius}
        fill={color}
        onMouseOver={() => onHoverHistogram(node.id)}
        onClick={() => {
          onClickHistogram(node.id)
          console.log("C112724 options", options);
          if(options){
            if (activePage === "explorer") {
              onSetActiveModuleId(options.variables[0].sort.moduleId);
            }else{
              onSetActiveDBModuleId(options.variables[0].sort.moduleId);
            }
          }
          onSetActiveIndicator(chartId.replace("_stacked_swarm", ""))
        }}
        className="swarm-circle"
        />
      );
      })}
      {/*console.log("B011025 nodes", nodes)}
      {console.log("B011025 nodes.findIndex(node => node.x ? node.x >= 0 : false) ", nodes.findIndex(node => node.x ? node.x >= 0 : false) )*/}
      <line 
        key={`line_${chartId}`}
        x1={`${nodes[(nodes.findIndex(node => node.x ? node.x >= 0 : false) > -1) ? nodes.findIndex(node => node.x ? node.x >= 0 : false) : 0]?.x}%`} 
        y1={`${((parseInt(height) / 2 - radius - padding) / parseInt(height)) * 100}%`} 
        x2={`${nodes[nodes.length - 1].x ? nodes[nodes.length - 1].x : 0}%`} 
        y2={`${((parseInt(height) / 2 - radius - padding) / parseInt(height)) * 100}%`} 
        stroke="rgba(0, 0, 0, 0.5)" 
        strokeWidth="0.75" 
        pointerEvents="none"
      />
      {
      axisLabels.map((label: any, i: number) => {
        const firstValidNodeIndex = nodes.findIndex(node => node.x ? node.x >= 0 : false);
        //console.log("E102224 firstValidNodeIndex", firstValidNodeIndex)
        if (firstValidNodeIndex === -1) return null;
        return (
          <div key={`cont_${i}_${chartId}`}>
        <line
          key={`tick_${i}_${chartId}`}
          x1={`${
            nodes
            ? i === 0 
          ? nodes[firstValidNodeIndex].x
          : i === 1
            ? (((nodes[nodes.length - 1]?.x ?? 0) - (nodes[firstValidNodeIndex]?.x ?? 0) ) / 2) + (nodes[firstValidNodeIndex]?.x ?? 0)
            : nodes[nodes.length - 1].x
            : 0 }%`}
          y1={`${((parseInt(height) / 2 - radius - padding) / parseInt(height)) * 100}%`} 
          x2={`${
            nodes
            ? i === 0 
          ? nodes[firstValidNodeIndex].x
          : i === 1
            ? (((nodes[nodes.length - 1]?.x ?? 0) - (nodes[firstValidNodeIndex]?.x ?? 0) ) / 2) + (nodes[firstValidNodeIndex]?.x ?? 0)
            : nodes[nodes.length - 1].x
            : 0 }%`}
          y2={`${((parseInt(height) / 2 - radius - padding) / parseInt(height)) * 100 + 7.5}%`} 
          stroke="rgba(0, 0, 0, 0.5)" 
          strokeWidth="0.75" 
          pointerEvents="none"
        />
        <text
          key={`text_${i}_${chartId}`}
          x={`${
            nodes
          ? i === 0
            ? nodes[firstValidNodeIndex].x
            : i === 1
            ? (((nodes[nodes.length - 1]?.x ?? 0) - (nodes[firstValidNodeIndex]?.x ?? 0)) / 2) + (nodes[firstValidNodeIndex]?.x ?? 0)
            : nodes[nodes.length - 1].x
          : 0
          }%`}
          y={`${((parseInt(height) / 2 - radius - padding) / parseInt(height)) * 100 + 15}%`}
          textAnchor={i === 0 ? "start" : i === 1 ? "middle" : "end"}
          fontSize="10px"
          fill="rgba(0, 0, 0, 0.75)"
          pointerEvents="none"
        >
          {d2gRound(label.tickValue, { DESCRIPTOR: DESCRIPTOR, countDecimalOverride: 0 })}
        </text>
          </div>
        );
      })}
    </svg>)}
  </div>)};
export default SwarmStatic;
