import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ExportToImage } from '../utilities/exports/image/ExportToImage';
import { ExportToExcel } from '../utilities/exports/xlsx/ExportToExcel';
import { onHoverHistogram, useGlobalHoveredId, useGlobalSelectedId, useGlobalActiveGeography, useGlobalActiveIndicator, useGlobalBivariateIndicator } from '../../data/StatusStore';
import XShareButton from './XShareButton';

interface ExportModalProps {
  title: string;
  displayName: string;
  year: string;
  isVisible: boolean;
  onClose: () => void;
  dataGeo: any;
  activeIndicator: string;
  bivariateIndicator: string | null;
}

// Use shouldForwardProp to prevent isVisible from being passed to the DOM
const ModalBackdrop = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== 'isVisible',
})<{ isVisible: boolean }>`
  display: ${(props) => (props.isVisible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999; /* High z-index */
`;

const ModalContainer = styled.div`
  position: fixed;
  top: 50vh;
  left: calc(50vw - 90px)  ;
  transform: translate(0%, -50%);
  background-color: #D3D3D3; /* Gray background */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  padding: 20px;
  max-width: 730px;
  height: 473px;
  z-index: 10000; /* Ensure it is above the backdrop */
  border: 1px solid #231f20;
`;

const ModalHeader = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ModalTitle = styled.h2`
  margin: 0;
  font-size: 20px;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
`;

const ModalContent = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ToggleItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
`;

const ToggleSwitch = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== 'isActive',
})<{ isActive: boolean }>`
  width: 40px;
  height: 20px;
  background-color: ${(props) => (props.isActive ? '#231f20' : '#8d8d8d')};
  border-radius: 20px;
  position: relative;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    width: 18px;
    height: 18px;
    background-color: white;
    border-radius: 50%;
    top: 1px;
    left: ${(props) => (props.isActive ? '20px' : '1px')};
    transition: left 0.3s ease;
  }
`;

const StyledRadio = styled.input.attrs({ type: 'radio' })`
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #000;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  outline: none;
  margin-right: 10px;

  &:checked::before {
    content: '';
    width: 12px;
    height: 12px;
    background-color: #000;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const StyledCheckbox = styled.input.attrs({ type: 'checkbox' })`
  appearance: none;
  width: 24px;
  height: 20px;
  border: 1px solid #000;
  border-radius: 50%; /* Make it round */
  position: relative;
  cursor: pointer;
  outline: none;
  margin-right: 10px;
  background-color: #fff;
  &:checked::before {
    content: '';
    width: 13px;
    height: 13px;
    background-color: #000;
    border-radius: 50%; /* Keep it round when checked */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;


const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 35px;
  gap: 10px;
`;

const ActionButton = styled.div`
  padding: 10px 15px;
  margin-left:10px;
  border: none;
  border-radius: 0px;
  background-color: none;
  border: 1px solid #231f20;
  color: #231f20;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  align-content: center;
  width: 124px;
  min-height: 34px;
  &:hover {
    color: #a29f9f;
    border: 1px solid #a29f9f;
  }
`;

const ActionButtonNarrow = styled.div`
  padding: 10px 15px;
  margin-left:10px;
  border: none;
  border-radius: 0px;
  background-color: none;
  border: 1px solid #231f20;
  color: #231f20;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  align-content: center;
  width: 80px;
  min-height: 34px;
  &:hover {
    color: #a29f9f;
    border: 1px solid #a29f9f;
  }
`;

const EmailInput = styled.input`
  padding: 8px;
  border: 1px solid #231f20;
  border-radius: 0px;
  font-size: 14px;
  width: 236px;
  background-color: #ffffff;

  /* Remove default focus outline and set custom border */
  &:focus {
    outline: none;
    border-color: #231f20;
  }
`;

const ExportModal: React.FC<ExportModalProps> = ({ title, isVisible, onClose, dataGeo, activeIndicator, bivariateIndicator, displayName, year }) => {

  //console.log("082524 dataGeo", dataGeo);
  //console.log("082524 activeIndicator", activeIndicator);
  //console.log("082524 bivariateIndicator", bivariateIndicator);

  const activeGeography = useGlobalActiveGeography();
  const selectedId = useGlobalSelectedId();

  const [includeMap, setIncludeMap] = useState<boolean>(true);
  const [email, setEmail] = useState<string>("");
  const [emailValid, setEmailValid] = useState<boolean>(false);
  const [emailFormstackReady, setEmailFormstackReady] = useState<boolean>(false);
  const [includeDesc, setIncludeDesc] = useState<boolean>(true);
  const [includeHighlightedArea, setIncludeHighlightedArea] = useState<boolean>(true);
  const [wantsUpdates, setWantsUpdates] = useState<boolean>(false);
  onHoverHistogram(null);


  const handleExport = async () => {
    const ids = ['mapHistContainer'];
    let spinner = document.getElementById('exportingSpinner')
    if(spinner){
      spinner.classList.remove('off');
    }
    let excludeTheseIds = [ 
      'exportModalBackdrop', 
      'mapRadios',
      'mapResetZoom',
      'legendExport',
    ];
    if (!includeHighlightedArea) {
      excludeTheseIds.push('mapSelecedIdBox');
      excludeTheseIds.push('selectedHistogramTooltip');
      excludeTheseIds.push('selectedHistogramSelectionBar');
      excludeTheseIds.push('hoveredHistogramTooltip');
      excludeTheseIds.push('hoveredHistogramSelectionBar');
    }
    if (!includeDesc) {
      excludeTheseIds.push('geoRaceContext');
      excludeTheseIds.push('descriptionContext');
      excludeTheseIds.push('sourceContext');
    }
    try {
      const formData = {
        form: '5981799', // Form ID for your proxy
        user_agent: navigator.userAgent, // Automatically capture the user agent
        remote_addr: 'Client IP', // You can replace this dynamically
        read: false,
        field_175573570: email, // Replace with dynamic values as needed
        field_175573577: activeIndicator, // Example field values
        field_175573621: new Date().toLocaleDateString('en-US'), // Current date in MM/DD/YYYY format
        field_176553700: wantsUpdates ? "yes" : "no", // Opt in?
        field_176554574: "image", //  download
        field_176555212: activeGeography // geo
      };
  
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      };
  
      const response = await fetch('http://35.209.24.52:8080/formstack-proxy', options);
  
      if (!response.ok) {
        throw new Error(`Server error: ${response.statusText}`);
      }
  
      const result = await response.json();
      console.log('Formstack submission successful:', result);
  
      console.log("B102324 ....FORMSTACK SUCCESSFULLY SUBMITTED");
      console.log('Exporting image...');
      
      // mapSelecedIdBox = selection box on map
      // mapRadios = radio buttons on map
      // mapResetZoom = reset zoom button on map
      ExportToImage(ids, `${activeIndicator}_${activeGeography}_${selectedId ? activeIndicator : "data2gonyc"}.png`, excludeTheseIds, includeHighlightedArea, includeDesc)
        .then(() => {
        // Call your function here
        //alert('Image exported successfully!');
        if(spinner){
          spinner.classList.add('off');
        }
        console.log('Image export completed.');
        })
        .catch((error) => {
        console.error('Error exporting image:', error);
      });
    } catch (error) {
      console.error('Error submitting to Formstack:', error);
      ExportToImage(ids, `${activeIndicator}_${activeGeography}_${selectedId ? activeIndicator : "data2gonyc"}.png`, excludeTheseIds, includeHighlightedArea, includeDesc)
        .then(() => {
        // Call your function here
        //alert('Image exported successfully!');
        if(spinner){
          spinner.classList.add('off');
        }
        console.log('Image export completed.');
        })
        .catch((error) => {
        console.error('Error exporting image:', error);
      });
    }
  };

  const handleExportXLSX = async () => {
    try {
      const formData = {
        form: '5981799', // Form ID for your proxy
        user_agent: navigator.userAgent, // Automatically capture the user agent
        remote_addr: 'Client IP', // You can replace this dynamically
        read: false,
        field_175573570: email, // Replace with dynamic values as needed
        field_175573577: activeIndicator, // Example field values
        field_175573621: new Date().toLocaleDateString('en-US'), // Current date in MM/DD/YYYY format
        field_176553700: wantsUpdates ? "yes" : "no", // Opt in?
        field_176554574: "xlsx", //  download
        field_176555212: activeGeography // geo
      };
  
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      };
  
      const response = await fetch('http://35.209.24.52:8080/formstack-proxy', options);
  
      if (!response.ok) {
        throw new Error(`Server error: ${response.statusText}`);
      }
  
      const result = await response.json();
      console.log('Formstack submission successful:', result);
  
      console.log("B102324 ....FORMSTACK SUCCESSFULLY SUBMITTED");
      // Call the function to export JSON to Excel
      ExportToExcel({ dataGeo: dataGeo, activeIndicator: activeIndicator, bivariateIndicator: bivariateIndicator, activeGeography:activeGeography });
  
    } catch (error) {
      console.error('Error submitting to Formstack:', error);
      ExportToExcel({ dataGeo: dataGeo, activeIndicator: activeIndicator, bivariateIndicator: bivariateIndicator, activeGeography:activeGeography });
    }
  };
  
  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const submitEmail = () => {
    console.log("A102324 email", email);
    if (!validateEmail(email)) {
      setEmailValid(false);
      return;
    }
    setEmailValid(true);
    const currentDate = new Date();
    const formattedDate = `${currentDate.getMonth() + 1}/${currentDate.getDate()}/${currentDate.getFullYear()}`;
    console.log("A102324 formattedDate", formattedDate);
    console.log("A102324 activeIndicator", activeIndicator);
    console.log("A102324 wantsUpdates", wantsUpdates);
  };

  useEffect (() => {
    console.log("D101724 email", email);
    if (!validateEmail(email)) {
      setEmailFormstackReady(false);
      setEmailValid(false);
      return;
    }
    setEmailFormstackReady(true);
    submitEmail();
  }, [email]);

  // X / Twitter
  var login = "ahdp";
	var api_key = "R_aad74fad57e78a962c0f92e3f9716cd6";
	var long_url = document.URL;

  return (
    <ModalBackdrop isVisible={isVisible} onClick={onClose}>
      <ModalContainer onClick={(e) => e.stopPropagation()}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '0px',
          }}
        >
          Share/Export
          <CloseButton onClick={onClose}>&times;</CloseButton>
        </div>
        <ModalHeader>
          <ModalTitle>{`${title}${year != "" ? ` (${year})` : null}`}</ModalTitle>
        </ModalHeader>
        <ModalContent>
          <div>Include:</div>
          {/*<ToggleItem>
            <ToggleSwitch isActive={includeMap} onClick={() => setIncludeMap(!includeMap)} />
            Include map?
          </ToggleItem>*/}
          <ToggleItem>
            <ToggleSwitch isActive={includeHighlightedArea} onClick={() => setIncludeHighlightedArea(!includeHighlightedArea)} />
            highlighted area?
          </ToggleItem>
          <ToggleItem>
            <ToggleSwitch isActive={includeDesc} onClick={() => setIncludeDesc(!includeDesc)} />
            description of stat and source info
          </ToggleItem>
          <div
            style={{
              width: '100%',
              height: '1px',
              backgroundColor: '#231f20',
              margin: '10px 0',
              marginTop: '30px',
              marginBottom: '20px',
            }}
          ></div>
          <ToggleItem>
            <StyledCheckbox
              checked={wantsUpdates}
              onChange={() => setWantsUpdates(!wantsUpdates)}
            />
            <label>
              Want to find out about updates and data releases from Measure of America? (We email every couple months!)
            </label>
          </ToggleItem>
          {/*<ToggleItem>
            <StyledRadio name="annotation" id="short" />
            <label htmlFor="short">Want to find out about updates and data releases from Measure of America? (We email every couple months!)</label>
          </ToggleItem>
          <ToggleItem>
            <StyledRadio name="annotation" id="full" defaultChecked />
            <label htmlFor="full">Full annotation</label>
          </ToggleItem>*/}
            <div
            style={{
              width: '100%',
              display: "flex",
              alignContent: "space-between",
            }}
            >  
            <EmailInput 
              type="email" 
              placeholder="enter email" 
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {/*<ActionButtonNarrow              
              onClick={emailFormstackReady ? submitEmail : undefined} 
              style={{ 
                color: emailFormstackReady ? '#231f20' : ' #a29f9f',
                cursor: emailFormstackReady ? 'pointer' : 'not-allowed',
                border: emailFormstackReady ? '1px solid #231f20' : '1px solid #a29f9f',
              }}>Submit</ActionButtonNarrow>*/}
            <div
              style={{
              width: 'calc(100% - 236px)',
              alignContent: 'center',
              paddingLeft: '15px',
              }}
            >Enter your email to download or export data and images</div>
            </div>
        </ModalContent>
        <ModalFooter>
          <div
            style={{
              marginLeft: '-10px',
              display: 'flex',
              justifyContent: 'flex-start',
            }}
          >
            <XShareButton longUrl={document.URL} apiKey={api_key} login={login}/>
            <ActionButton 
              onClick={emailValid ? handleExport : undefined} 
              style={{ 
              color: emailValid ? '#231f20' : ' #a29f9f',
              cursor: emailValid ? 'pointer' : 'not-allowed',
              border: emailValid ? '1px solid #231f20' : '1px solid #a29f9f',
              }}
              onMouseEnter={(e) => {
              if (emailValid) {
                (e.target as HTMLElement).style.color = '#a29f9f';
                (e.target as HTMLElement).style.border = '1px solid #a29f9f';
              }
              }}
              onMouseLeave={(e) => {
              if (emailValid) {
                (e.target as HTMLElement).style.color = '#231f20';
                (e.target as HTMLElement).style.border = '1px solid #231f20';
              }
              }}
            >
              Export as .png
            </ActionButton>
            <ActionButton 
              onClick={emailValid ? handleExportXLSX : undefined} 
              style={{ 
                color: emailValid ? '#231f20' : ' #a29f9f',
                cursor: emailValid ? 'pointer' : 'not-allowed',
                border: emailValid ? '1px solid #231f20' : '1px solid #a29f9f',
                }}
                onMouseEnter={(e) => {
                if (emailValid) {
                  (e.target as HTMLElement).style.color = '#a29f9f';
                  (e.target as HTMLElement).style.border = '1px solid #a29f9f';
                }
                }}
                onMouseLeave={(e) => {
                if (emailValid) {
                  (e.target as HTMLElement).style.color = '#231f20';
                  (e.target as HTMLElement).style.border = '1px solid #231f20';
                }
                }}
            >
              Export as .xls
            </ActionButton>
          </div>
            <ActionButton as="a" href="https://ssrc.formstack.com/forms/moa_d2g" target="_blank" rel="noopener noreferrer" style={{textDecoration:'none', width:'160px'}}>Download ALL Data</ActionButton>
           {/*
            <FormStackJavaScriptEmbed />
            <iframe src="https://ssrc.formstack.com/forms/d2g2_data_download" title="Formstack Form" width="600" height="400"></iframe>*
            <FormStack />
            <FormStackLightBox />
            <FormStackJavaScript />*/}
        </ModalFooter>
      </ModalContainer>
    </ModalBackdrop>
  );
};

export default ExportModal;
