// Colors.tsx

const Colors = {
    moaOranges: ['#cf802a','#e3a527','#ffc428','#f7d97c','#ffe8a7'].reverse(),
    moaYellows: ['#6c5300','#b18a18','#ffc425','#ffd984','#ffefc9'].reverse(),
    moaReds: ['#5f1e00','#a0411a','#f26531','#faae89','#fddcc9'].reverse(),
    moaTeals: ['#00404a','#007c8b','#00b5cc','#8dd5e2','#d2edf3'].reverse(),
    moaGreens: ['#414c00','#7e8a14','#b0bc22','#d4d98f','#ecefcf'].reverse(),
    moaPinks: ['#5e0e3e','#98316a','#d7418c','#ec94b8','#f6d1e1'].reverse(),
    moaBlues: ['#00234f','#0b4781','#135eab','#98abd5','#d3dcee'].reverse(),
    moaPurples: ['#342a4f','#5a4f82','#8b6fc9','#c4b4e5','#e6e1f4'].reverse(),
    // Variants, mostly for when some colors are too dark for their context. Diffeences noted in line comments 
    moaOrangesVar: ['#cf802a','#e3a527','#ffc428','#f7d97c','#ffe8a7'].reverse(),
    moaYellowsVar: ['#6c5300','#b18a18','#ffc425','#ffd984','#ffefc9'].reverse(),
    moaRedsVar: ['#5f1e00','#a0411a','#f26531','#faae89','#fddcc9'].reverse(),
    moaTealsVar: ['#00404ad6','#007c8b','#00b5cc','#8dd5e2','#d2edf3'].reverse(), // Alter position [0] to have transparency
    moaGreensVar: ['#414c00d6','#7e8a14','#b0bc22','#d4d98f','#ecefcf'].reverse(),// Alter position [0] to have transparency
    moaPinksVar: ['#5e0e3e','#98316a','#d7418c','#ec94b8','#f6d1e1'].reverse(),
    moaBluesVar: ['#00234f','#0b4781','#135eab','#98abd5','#d3dcee'].reverse(),
    moaPurplesVar: ['#342a4fd6','#5a4f82','#8b6fc9','#c4b4e5','#e6e1f4'].reverse(),// Alter position [0] to have transparency
    moaGrays: ['#231f20','#4d4d4d','#888888','#bcbec0','#e8e8e8'].reverse(),
    moaNA: '#888888',
    highlightHover: '#e11384',
    highlightSelect: '#000',
    black: '#231f20',
    processBlack: "#231f20",
    processBlack30pct: "#bcbec0",
    white: "#fff",
    originalBivariateColorScheme: [ 
        ['#00AEC4', '#008B6F', '#00801C'], // Low value1
        ['#A9CDD6', '#9FB283', '#A2971E'], // Medium value1
        ['#E8E8E8', '#EFDE9C', '#F5BC24']  // High value1
    ],
    originalBivariateColorSchemeStandard: [ 
        ['#00801C', '#A2971E', '#F5BC24'], // Low value1
        ['#008B6F', '#9FB283', '#EFDE9C'], // Medium value1
        ['#00AEC4', '#A9CDD6', '#E8E8E8']  // High value1
    ],
    bivariateColorScheme: [ 
        ['#4591ce', '#4361a9', '#492753'], // Low value1
        ['#93badf', '#9179ae', '#a31d35'], // Medium value1
        ['#d6b8d8', '#d66c90', '#de3252']  // High value1
    ],
    bivariateColorSchemeStandard: [ 
        ['#492753', '#a31d35', '#de3252'], // Low value1
        ['#4361a9', '#9179ae', '#d66c90'], // Medium value1
        ['#4591ce', '#93badf', '#d6b8d8']  // High value1
    ],


    wafflePieColors: [
        "#7F9EC1",//"#00B5CC",
        "#DF9C3F",//"#F26531",
        "#E3A8C8",//"#FFC425",
        "#6AC49A",//"#B0BC22",
        "#DDC340",//"#8B8B8B",
        "#CCCCCC",// Below this line are made-up colors for cases where more classes exist
        "#e0ac2b",
        "#e85252",
        "#6689c6",
        "#9a6fb0",
        "#a53253",
        "#69b3a2",
        "#0d6efd",
        "#0dcaf0",
        "#141660",
        "#3fdb16",
        "#cff354",
        "#be54f3",
        "#f354ad",
        "#f37154",
    ],
    getColorByClass: (className: string) => {
        let color = "#231f20";
        switch (className) {
            case 'Basics':
                color = Colors.moaYellows[2];
                break;
            case 'Health':
                color = Colors.moaReds[2];
                break;
            case 'Work + Wealth':
                color = Colors.moaGreens[2];
                break;
            case 'Physical World':
                color = Colors.moaPurples[2];
                break;
            case 'Custom':
                color = Colors.black;
                break;
            case 'Education':
                color = Colors.moaTeals[2];
                break;
            case 'Civics':
                color = Colors.moaPinks[2];
                break;
            case 'Safety':
                color = Colors.moaBlues[2];
                break;
            case 'Race & Gender':
                color = Colors.moaGreens[2];
                break;
            case 'Auto Generated':
                color = Colors.moaBlues[2];
                break;
                //Module Color
            case 'yellow':
                color = Colors.moaYellows[2];
                break;
            case 'green':
                color = Colors.moaGreens[2];
                break;
            case 'blue':
                color = Colors.moaTeals[2];
                break;
            case 'red':
                color = Colors.moaReds[2];
                break;
            case 'pink':
                color = Colors.moaPinks[2];
                break;
            case 'purple':
                color = Colors.moaPurples[2];
                break;
            case 'dark blue':
                color = Colors.moaBlues[2];
                break;
            // variants
            case 'yellow_variant':
                color = Colors.moaYellowsVar[2];
                break;
            case 'green_variant':
                color = Colors.moaGreensVar[2];
                break;
            case 'blue_variant':
                color = Colors.moaTealsVar[2];
                break;
            case 'red_variant':
                color = Colors.moaRedsVar[2];
                break;
            case 'pink_variant':
                color = Colors.moaPinksVar[2];
                break;
            case 'purple_variant':
                color = Colors.moaPurplesVar[2];
                break;
            case 'dark blue_variant':
                color = Colors.moaBluesVar[2];
                break;
            default:
                color = Colors.moaYellows[2];
                break;
        }
        return color;
    },
    getColorQuintile(value: number, min: number, max: number, colorClass?: string, _quintile?:number): string {
        /*  
            Basics
            Work + Wealth
            Physical World
            Education
            Health
            Civics
            Safety
            Race & Gender
            Auto Generated
        */
        //console.log("A010825 colorClass", colorClass); 
        let colors = Colors.moaYellows;

        switch (colorClass) {
            // Category 1
            case 'Basics':
                colors = Colors.moaYellows;
                break;
            case 'Health':
                colors = Colors.moaReds;
                break;
            case 'Work + Wealth':
                colors = Colors.moaGreens;
                break;
            case 'Physical World':
                colors = Colors.moaPurples;
                break;
            case 'Custom':
                colors = Colors.moaPurples;
                break;
            case 'Education':
                colors = Colors.moaTeals;
                break;
            case 'Civics':
                colors = Colors.moaPinks;
                break;
            case 'Safety':
                colors = Colors.moaBlues;
                break;
            case 'Race & Gender':
                colors = Colors.moaGreens;
                break;
            case 'Auto Generated':
                colors = Colors.moaBlues;
                break;
            //Module Color
            case 'yellow':
                colors = Colors.moaYellows;
                break;
            case 'green':
                colors = Colors.moaGreens;
                break;
            case 'blue':
                colors = Colors.moaTeals;
                break;
            case 'red':
                colors = Colors.moaReds;
                break;
            case 'pink':
                colors = Colors.moaPinks;
                break;
            case 'purple':
                colors = Colors.moaPurples;
                break;
            case 'dark blue':
                colors = Colors.moaBlues;
                break;
            // variants
            case 'yellow_variant':
                colors = Colors.moaYellowsVar;
                break;
            case 'green_variant':
                colors = Colors.moaGreensVar;
                break;
            case 'blue_variant':
                colors = Colors.moaTealsVar;
                break;
            case 'red_variant':
                colors = Colors.moaRedsVar;
                break;
            case 'pink_variant':
                colors = Colors.moaPinksVar;
                break;
            case 'purple_variant':
                colors = Colors.moaPurplesVar;
                break;
            case 'dark blue_variant':
                colors = Colors.moaBluesVar;
                break;
            default:
                colors = Colors.moaYellowsVar;
                break;
        }
        if (_quintile !== undefined) {
            return _quintile > colors.length - 1 ? Colors.moaGrays[_quintile % Colors.moaGrays.length] : colors[_quintile % colors.length];
        }
        const range = max - min;
        const quintile = range / 5;
        let color = Colors.moaNA; // Default to "N/A" color
        
        if (value < min || value > max) {
            //console.log("G061024 value === max", value, max);
            return color;
        }
    
        for (let i = 0; i < 5; i++) {
            if (value < min + (quintile * (i + 1))) {
                return colors[i];//Colors.moaOranges[i];
            }
            if (value === max) {
                return colors[4];//Colors.moaOranges[4];
            }
        }
    
        return color; // Fallback to default if needed
    },
    getStandardBivariateColor(value:number, min:number, max:number, value2:number, min2:number, max2:number) { 
        // Helper function to categorize the value on a bivariate scheme
        // [v1 low, v2 low]        [ ]        [v1 low, v2 high] 
        // [ ]                     [ ]        [ ]
        // [v1 high, v2 low]       [ ]        [v1 high, v2 high]
        // Standard Bivariate Color Scheme. 
        const categorizeValue = (val:number, minValue:number, maxValue:number) => {
          const range = (maxValue - minValue) / 3;
          if (val <= minValue + range) return 0; // Low
          else if (val <= minValue + 2 * range) return 1; // Medium
          else return 2; // High
        };
      
        // Categorize both values
        const category1 = categorizeValue(value, min, max);
        const category2 = categorizeValue(value2, min2, max2);
      
        // Select color from the scheme based on categories
        //console.log("C070724 Colors.bivariateColorScheme[category1][category2];", Colors.bivariateColorScheme[category1][category2])
        return Colors.bivariateColorScheme[category1][category2];
    },
    _getInvertedBivariateColor(value:number, min:number, max:number, value2:number, min2:number, max2:number) { 
        // Helper function to categorize the value on a bivariate scheme
        // [v1 high, v2 low]      [ ]        [v1 high, v2 high] 
        // [ ]                    [ ]        [ ]
        // [v1 low, v2 low]       [ ]        [v1 low, v2 high]
        // Inverted Bivariate Color Scheme.
        const categorizeValue = (val:number, minValue:number, maxValue:number) => {
            const range = (maxValue - minValue) / 3;
            if (val <= minValue + range) return 2; // High (inverted for value1)
            else if (val <= minValue + 2 * range) return 1; // Medium remains the same
            else return 0; // Low (inverted for value1)
        }; 
        const categorizeValue2 = (val:number, minValue:number, maxValue:number) => {
            const range = (maxValue - minValue) / 3;
            if (val <= minValue + range) return 0; // Low
            else if (val <= minValue + 2 * range) return 1; // Medium
            else return 2; // High
        };
    
        // Categorize both values
        const category1 = categorizeValue(value, min, max); // Flipped for value1
        const category2 = categorizeValue2(value2, min2, max2); // Standard for value2
        //console.log("A070824 category1", category1);
        //console.log("A070824 category2", category2);
        // Select color from the scheme based on categories
        return Colors.bivariateColorScheme[category1][category2];
    },
    getInvertedBivariateColor(caller:string, obj:any, value:any, min:number, max:number, value2:any, min2:number, max2:number, overRide:any = null, valueOverRide:any = null, valueOverRide2:any = null) { 
        // Helper function to categorize the value on a bivariate scheme
        // [v1 high, v2 low]      [ ]        [v1 high, v2 high] 
        // [ ]                    [ ]        [ ]
        // [v1 low, v2 low]       [ ]        [v1 low, v2 high]
        // Inverted Bivariate Color Scheme.
        /*
        console.log("D071624 overRide", overRide);
        console.log("D071624 valueOverRide", valueOverRide2);
        console.log("D071624 valueOverRide2", valueOverRide2);
        */
        //console.log("D071624 caller", caller);
        if (valueOverRide === "NA"){ return Colors.moaNA; }
        if (valueOverRide2 === "NA"){return Colors.moaNA; }
        //if (value === "NA"){ return Colors.moaNA; }
        //if (value2 === "NA"){ return Colors.moaNA; }
        // FOR TRIAGE!!!
        //if (obj == "36047017700"){
        //if (caller == "choropleth"){
        //    console.log("H112824",caller, obj, value, min, max, value2, min2, max2, overRide, valueOverRide, valueOverRide2)
        //}
        if (overRide) {
            const categorizeValue = (val:number | null, newBreaks:any) => {
                if (val !== null && val !== undefined){ // Medium remains the same
                    if (val <= newBreaks[0]) return 0; // Low
                    else if (val <= newBreaks[1]) return 1; // Medium
                    else return 2; // High
                }else{
                    //console.log("F112824 val", val);
                    return null; // Low
                }
            }; 
            const categorizeValue2 = (val:number | null, newBreaks:any) => {
                if (val !== null && val !== undefined){ // Medium remains the same
                    if (val <= newBreaks[0]) return 2; // High
                    else if (val <= newBreaks[1]) return 1; // Medium
                    else return 0; // Low
                }else{
                    console.log("F112824 val", val);
                    return null; // High
                }
            }; 

            // Categorize both values
            const category1 = categorizeValue2(valueOverRide, overRide.x); // Flipped for value1
            const category2 = categorizeValue(valueOverRide2, overRide.y); // Standard for value2

            // Select color from the scheme based on categories
            return category1!==null && category2!==null ? Colors.bivariateColorScheme[category1][category2] : Colors.moaNA;
        }else{
            const categorizeValue = (val:number, minValue:number, maxValue:number) => {
                if (val !== null && val !== undefined){
                    const range = (maxValue - minValue) / 3;
                    if (val <= minValue + range) return 2; // High (inverted for value1)
                    else if (val <= minValue + 2 * range) return 1; // Medium remains the same
                    else return 0; // Low (inverted for value1)
                }else{
                    //console.log("F112824 val", val);
                    return null; // High
                }
            }; 
            const categorizeValue2 = (val:number, minValue:number, maxValue:number) => {
                if (val !== null && val !== undefined){
                    const range = (maxValue - minValue) / 3;
                    if (val <= minValue + range) return 0; // Low
                    else if (val <= minValue + 2 * range) return 1; // Medium
                    else return 2; // High
                }else{
                    //console.log("F112824 val", val);
                    return null; // Low
                }
            };
            // Categorize both values
            const category1 = categorizeValue(value2, min2, max2); // Flipped for value1
            const category2 = categorizeValue2(value, min, max); // Standard for value2
            //console.log("A070824 category1", category1);
            //console.log("A070824 category2", category2);
            // Select color from the scheme based on categories
            //return Colors.bivariateColorScheme[category1][category2];
            return category1!==null && category2!==null ? Colors.bivariateColorScheme[category1][category2] : Colors.moaNA;
        }
    }
};
  
  export default Colors;
  